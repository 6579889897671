import { Injectable } from '@angular/core'
import {
  initialize,
  LDClient,
  LDUser as User,
} from 'launchdarkly-js-client-sdk'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter, first, map } from 'rxjs/operators'

export { User }

const ANONYMOUS: string = 'anonymous'

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService {
  initialize(envKey: string, user: User): void {
    this.client = initialize(envKey, user)

    this.client.on('initialized', () => {
      this.clientSub.next(this.client)
    })

    this.client.on('change', () => {
      this.clientSub.next(this.client)
    })
  }

  getFlags(flag: string): Observable<boolean> {
    return this.client$.pipe(
      filter((client: LDClient) => client !== null),
      first(),
      map((client: LDClient) => client.variation(flag, false))
    )
  }

  changeUser(user: User): void {
    this.client.identify(user)
  }

  private client: LDClient
  private clientSub: BehaviorSubject<LDClient> = new BehaviorSubject(null)
  private readonly client$: Observable<LDClient> = this.clientSub.asObservable()
}

export function getLDUser(userAccount?: User): User {
  return (
    userAccount || {
      key: ANONYMOUS,
      anonymous: true,
    }
  )
}
