import { Location } from '@angular/common'
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule, Routes } from '@angular/router'
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader,
} from '@gilsdav/ngx-translate-router'
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import {
  IconRegistryService,
  IconsModule,
} from '@tba/portal/shared/features/icons'
import { SharedComponentsModule } from '@tba/shared/components'
import {
  FeatureFlagsModule,
  FEATURE_FLAGS_INIT,
} from '@tba/shared/features/feature-flags'
import { ThemingService } from '@tba/shared/features/theme-selector'
import { getLDUser } from '@tba/shared/providers'

import { environment } from '../environments/environment'

import { AppComponent } from './app.component'
import { CheckoutDetailsModule } from './checkout-details/checkout-details.module'
import { AuthInterceptor } from './shared/providers/auth-interceptor'

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./checkout-details/checkout-details.module').then(
        (m: { CheckoutDetailsModule: CheckoutDetailsModule }) =>
          m.CheckoutDetailsModule
      ),
  },
]

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedComponentsModule,
    RouterModule.forRoot(routes),
    TranslateModule.forRoot({
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient],
      },
    }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (
          translate: TranslateService,
          location: Location,
          settings: LocalizeRouterSettings
        ) =>
          new ManualParserLoader(
            translate,
            location,
            settings,
            environment.supportedLanguages
          ),
        deps: [TranslateService, Location, LocalizeRouterSettings],
      },
    }),
    IconsModule,
    FeatureFlagsModule.forRoot({
      loader: {
        provide: FEATURE_FLAGS_INIT,
        useValue: {
          envKey: environment.ldKey,
          user: getLDUser(),
        },
      },
    }),
  ],
  providers: [
    ThemingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(registry: IconRegistryService) {
    registry.registerIconSet('assets/icon-set.svg')
  }
}
