import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { getUrlParamFallback } from '@tba/shared/utils'
import Cookies from 'js-cookie'

import { environment } from '../../../environments/environment'

const STORAGE_KEY_ACCESS_TOKEN: string = 'accessToken'

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  token: string
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.token =
      getUrlParamFallback(this.document.location.href, 'token') ||
      Cookies.get(`${environment.environment}_${STORAGE_KEY_ACCESS_TOKEN}`) ||
      ' '
  }
}
