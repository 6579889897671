import { Component } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { environment } from '../environments/environment'

import { BrandService } from './shared/providers/brand.service'

@Component({
  selector: 'checkout-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent {
  constructor(translate: TranslateService, brandService: BrandService) {
    translate.setDefaultLang(environment.defaultLang)
    brandService.initializeBrand()
    document.body.classList.add(`${brandService.brand.theme}-theme`)
  }
}
