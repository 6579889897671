import { Injectable } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

@Injectable()
export class IconRegistryService {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  registerIcon(name: string, path: string): void {
    const url: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      path
    )

    this.iconRegistry.addSvgIcon(name, url)
  }

  registerIconSet(path: string): void {
    const safeResourceUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      path
    )

    this.iconRegistry.addSvgIconSet(safeResourceUrl)
  }
}
