import { ICellRendererParams } from '@ag-grid-community/all-modules'
import { ICellRendererAngularComp } from '@ag-grid-community/angular'
import { Component } from '@angular/core'

@Component({
  selector: 'tba-data-grid-checkbox',
  templateUrl: './data-grid-checkbox.component.html',
  styleUrls: ['./data-grid-checkbox.component.scss'],
})
export class DataGridCheckboxComponent implements ICellRendererAngularComp {
  params: ICellRendererParams

  agInit(params: ICellRendererParams): void {
    this.params = params
  }

  checkedHandler(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement
    const checked: boolean = target.checked
    const colId: string = this.params.column.getColId()
    this.params.node.setDataValue(colId, checked)
  }

  refresh(): boolean {
    return false
  }
}
