import {
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
} from '@angular/cdk/overlay'
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal'
import { Injectable, Injector } from '@angular/core'
// tslint:disable-next-line: nx-enforce-module-boundaries
import { ModalComponent, ModalRef } from '@tba/shared/components'
import { ModalParams } from '@tba/shared/models'

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private overlay: Overlay, private injector: Injector) {}

  open<Input = any, Output = any>({
    title,
    content,
    data,
    width = 530,
    height,
    origin,
    disableClose,
    config,
    minWidth,
  }: ModalParams<Input>): ModalRef {
    const overlayRef: OverlayRef = this.overlay.create(
      this.getOverlayConfig({ origin, width, height, minWidth })
    )
    const modalRef: ModalRef = new ModalRef<Input, Output>(
      overlayRef,
      title,
      content,
      data,
      disableClose,
      config
    )
    const injector: PortalInjector = this.createInjector(
      modalRef,
      this.injector
    )

    overlayRef.attach(new ComponentPortal(ModalComponent, null, injector))

    return modalRef
  }

  createInjector(popoverRef: ModalRef, injector: Injector): PortalInjector {
    const tokens: WeakMap<typeof ModalRef, ModalRef<{}>> = new WeakMap([
      [ModalRef, popoverRef],
    ])
    return new PortalInjector(injector, tokens)
  }

  getOverlayConfig({
    origin,
    width,
    height,
    minWidth,
  }: {
    origin: HTMLElement
    width: string | number
    height: string | number
    minWidth: string | number
  }): OverlayConfig {
    return new OverlayConfig({
      width,
      height,
      minWidth,
      hasBackdrop: true,
      backdropClass: 'dark-modal-backdrop',
      positionStrategy: this.getOverlayPosition(origin),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    })
  }

  private getOverlayPosition(origin: HTMLElement): PositionStrategy {
    const positionStrategy: PositionStrategy = origin
      ? this.overlay
          .position()
          .flexibleConnectedTo(origin)
          .withPositions(this.getPositions())
          .withPush(false)
      : this.overlay.position().global().centerHorizontally().centerVertically()

    return positionStrategy
  }

  private getPositions(): ConnectionPositionPair[] {
    return [
      {
        originX: 'start',
        originY: 'center',
        overlayX: 'end',
        overlayY: 'center',
      },
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
      },
    ]
  }
}
