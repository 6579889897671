import { Environment } from './environment.model'

export const environment: Environment = {
  production: false,
  baseApiUrl: 'https://acc-shared-api.tbauctions.com',
  checkoutEndpoint: 'https://acc-shared-api.tbauctions.com/checkout',
  buyersEndpoint: 'https://acc-shared-api.tbauctions.com/buyers',
  postcodeEndpoint: 'https://acc-shared-api.tbauctions.com/postcode',
  paymentsEndpoint: {
    bva: 'https://acc-payment.bva-auctions.com',
    troostwijk: 'https://acc-payment.twa.nl',
  },
  bvaEndpoint: 'https://acc.bva-auctions.com',
  twkEndpoint: 'https://stage.twa.nl',
  defaultLang: 'en',
  supportedLanguages: ['en', 'de', 'nl'],
  environment: 'acc',
  ldKey: '5c6d1c2721d7462e2ab265ef',
}
