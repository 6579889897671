import { OverlayRef } from '@angular/cdk/overlay'
import { ModalCloseEvent, ModalConfig, ModalContent } from '@tba/shared/models'
import { Observable, Subject } from 'rxjs'

export class ModalRef<Input = any, Output = any> {
  afterClosed$: Observable<ModalCloseEvent<Output>>

  constructor(
    public overlay: OverlayRef,
    public title: string,
    public content: ModalContent,
    public data: Input,
    public disableClose: boolean = false,
    public config?: ModalConfig
  ) {
    this.afterClosed$ = this.afterClosed.asObservable()
    overlay.backdropClick().subscribe(() => {
      if (!disableClose) {
        this._close('backdropClick', null)
      }
    })
  }

  close(data?: Output): void {
    this._close('close', data)
  }

  private afterClosed: Subject<ModalCloseEvent<Output>> = new Subject<
    ModalCloseEvent<Output>
  >()

  private _close(type: 'backdropClick' | 'close', data: Output): void {
    this.overlay.dispose()
    this.afterClosed.next({ type, data })
    this.afterClosed.complete()
  }
}
