import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { PaginationPageSizesComponent } from './components/pagination-page-sizes/pagination-page-sizes.component'
import { PaginationRangesComponent } from './components/pagination-ranges/pagination-ranges.component'
import { PaginationComponent } from './components/pagination/pagination.component'
import { PaginationService } from './providers/pagination.service'

@NgModule({
  imports: [CommonModule],
  declarations: [
    PaginationComponent,
    PaginationPageSizesComponent,
    PaginationRangesComponent,
  ],
  exports: [PaginationComponent],
  providers: [PaginationService],
})
export class PaginationModule {}
