export interface IconStyle {
  stroke: string
  width: string
  height: string
}

export const DEFAULT_ICON_STYLE: IconStyle = {
  stroke: '#006EB3',
  height: '13px',
  width: '13px',
}
