import { Injectable } from '@angular/core'
import { StyleModel } from '@tba/stripe'

import {
  ChannelPallet,
  CHANNEL_PALLET,
  PalletType,
} from '../models/channel-pallet.model'
import { CHANNEL_STYLES } from '../models/channel-style.model'
import { DEFAULT_ICON_STYLE, IconStyle } from '../models/icon-style.model'

@Injectable({
  providedIn: 'root',
})
export class ThemingService {
  channelStyle(channel: string): StyleModel {
    return CHANNEL_STYLES[channel] || CHANNEL_STYLES.default
  }

  channelPallet(palletType: PalletType): ChannelPallet {
    return CHANNEL_PALLET[palletType]
  }

  iconStyle(): IconStyle {
    return DEFAULT_ICON_STYLE
  }
}
