import { Injectable } from '@angular/core'
import { forkJoin, iif, Observable, of, range } from 'rxjs'
import { map, toArray } from 'rxjs/operators'

import { Pagination } from '../models/pagination.model'

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  getPagination(
    currentPage: number,
    pageRange: number,
    pageAmount: number
  ): Observable<Pagination> {
    const startRange$: Observable<number[]> = iif(
      () => this.isMultiRangedPagination(pageRange, pageAmount),
      this.getStartRange(currentPage, pageRange),
      range(1, pageAmount).pipe(toArray())
    )
    const midRange$: Observable<number[]> = iif(
      () => this.isMultiRangedPagination(pageRange, pageAmount),
      this.getMidRange(currentPage, pageRange, pageAmount),
      of([])
    )
    const endRange$: Observable<number[]> = iif(
      () => this.isMultiRangedPagination(pageRange, pageAmount),
      this.getEndRange(currentPage, pageRange, pageAmount),
      of([])
    )

    return forkJoin([startRange$, midRange$, endRange$]).pipe(
      map((ranges: number[][]) => {
        return {
          startRange: ranges[0],
          midRange: ranges[1],
          endRange: ranges[2],
          currentPage,
          pageAmount,
          isInStartingRange: this.isInStartingRange(currentPage, pageRange),
          isInOuterRange: this.isInOuterRange(
            currentPage,
            pageRange,
            pageAmount
          ),
        }
      })
    )
  }

  private getStartRange(
    currentPage: number,
    pageRange: number
  ): Observable<number[]> {
    if (!this.isInStartingRange(currentPage, pageRange)) {
      return of([1])
    }

    return range(1, pageRange).pipe(toArray())
  }

  private getMidRange(
    currentPage: number,
    pageRange: number,
    pageAmount: number
  ): Observable<number[]> {
    if (this.isPageInOutherBounds(currentPage, pageRange, pageAmount)) {
      return of([])
    }

    return range(currentPage - (pageRange - 1) / 2, pageRange).pipe(toArray())
  }

  private getEndRange(
    currentPage: number,
    pageRange: number,
    pageAmount: number
  ): Observable<number[]> {
    if (!this.isInOuterRange(currentPage, pageRange, pageAmount)) {
      return of([pageAmount])
    }

    return range(pageAmount - pageRange + 1, pageRange).pipe(toArray())
  }

  private isInStartingRange(currentPage: number, pageRange: number): boolean {
    return currentPage <= pageRange
  }

  private isInOuterRange(
    currentPage: number,
    pageRange: number,
    pageAmount: number
  ): boolean {
    return pageAmount - pageRange + 1 <= currentPage
  }

  private isMultiRangedPagination(
    pageRange: number,
    pageAmount: number
  ): boolean {
    return (
      pageAmount > pageRange && !this.doRangesOverlap(pageRange, pageAmount)
    )
  }

  private doRangesOverlap(pageRange: number, pageAmount: number): boolean {
    return pageAmount - pageRange + 1 <= pageRange
  }

  private isPageInOutherBounds(
    currentPage: number,
    pageRange: number,
    pageAmount: number
  ): boolean {
    return (
      this.isInStartingRange(currentPage, pageRange) ||
      this.isInOuterRange(currentPage, pageRange, pageAmount)
    )
  }
}
