<div class="tba-modal">
  <header
    class="tba-modal__header"
    [class.tba-modal__header--with-subtitle]="config?.headerSubtitleText"
    *ngIf="title"
  >
    <a
      *ngIf="!disableClose"
      class="tba-modal__header__close"
      (click)="onCloseModal()"
      title="Close"
    >
      <img src="assets/shared/components/close.svg" alt="close" height="20px" />
    </a>
    <h3 class="tba-modal__header__title" [innerHtml]="title"></h3>
    <p
      *ngIf="config?.headerSubtitleText"
      class="tba-modal__header--without-margins"
      [translate]="config?.headerSubtitleText"
    ></p>
  </header>
  <section
    class="tba-modal__content"
    [class.tba-modal__content--no-content-padding]="config?.noContentPadding"
  >
    <ng-container [ngSwitch]="renderMethod">
      <div *ngSwitchCase="'text'" [innerHTML]="content"></div>

      <ng-container *ngSwitchCase="'template'">
        <ng-container
          *ngTemplateOutlet="content; context: context"
        ></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'component'">
        <ng-container *ngComponentOutlet="content"></ng-container>
      </ng-container>
    </ng-container>
  </section>
</div>
