import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations'

export const fadeIn: AnimationTriggerMetadata = trigger('fadeIn', [
  transition(
    ':enter',
    [style({ opacity: 0 }), animate('{{ duration }} ease-in-out')],
    { params: { duration: '300ms' } }
  ),
])

export const rotatedState: AnimationTriggerMetadata = trigger('rotatedState', [
  state('0', style({ transform: 'rotate(0)' })),
  state('90', style({ transform: 'rotate(90deg)' })),
  state('180', style({ transform: 'rotate(180deg)' })),
  state('270', style({ transform: 'rotate(270deg)' })),
  state('-90', style({ transform: 'rotate(-90deg)' })),
  state('-180', style({ transform: 'rotate(-180deg)' })),
  state('-270', style({ transform: 'rotate(-270deg)' })),
  transition('* => *', animate('200ms ease-in')),
])
