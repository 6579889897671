export function getQueryParameter(url: string, key: string): string {
  return URLSearchParams
    ? getUrlParamSearchAPI(url, key)
    : getUrlParamFallback(url, key)
}

export function getUrlParamSearchAPI(url: string, key: string): string {
  const search: string = url.split('?')[1]
  const urlParams: URLSearchParams = new URLSearchParams(search)
  return urlParams.get(key) || ''
}

export function getUrlParamFallback(url: string, key: string): string {
  const name: string = key.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex: RegExp = new RegExp(`[\\?&]${name}=([^&#]*)`)
  const results: RegExpExecArray = regex.exec(url)
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export function addQueryParamToUrl(
  url: string,
  key: string,
  value: string
): string {
  const redirectUrl: string = url.split('?')[0]
  const query: string = url.split('?')[1]
  const redirectQuery: string = query
    ? `${query}&${key}=${value}`
    : `${key}=${value}`

  return `${redirectUrl}?${redirectQuery}`
}
