import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

import { FeatureFlagsService } from './feature-flags.service'

@Injectable()
export class FeatureFlagsGuard implements CanActivate {
  constructor(
    private featureFlagsService: FeatureFlagsService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const featureFlagGuard: string = next.data.featureFlagGuard

    if (!featureFlagGuard) {
      return false
    }

    return (
      this.featureFlagsService.getFlags(featureFlagGuard) as Observable<boolean>
    ).pipe(tap((allowed: boolean) => !allowed && this.router.navigate(['/'])))
  }
}
