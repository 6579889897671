import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { TokenService } from './token.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService) {}

  intercept(
    request: HttpRequest<void>,
    next: HttpHandler
  ): Observable<HttpEvent<void>> {
    const accessToken: string = this.tokenService.token

    const Authorization: string = 'Bearer ' + accessToken
    const headers: HttpHeaders = new HttpHeaders({ Authorization, accessToken })

    return next.handle(request.clone({ headers, url: request.url }))
  }
}
