import { AgGridModule } from '@ag-grid-community/angular'
import { OverlayModule } from '@angular/cdk/overlay'
import { PortalModule } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { RouterModule } from '@angular/router'
import { NgSelectModule } from '@ng-select/ng-select'
import { TranslateModule } from '@ngx-translate/core'
import { CountryFlagModule } from '@tba/portal/shared/features/country-flag'
import { IconsModule } from '@tba/portal/shared/features/icons'
import { PaginationModule } from '@tba/portal/shared/features/pagination'

import { AnnouncementBannerComponent } from './announcement-banner/announcement-banner.component'
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component'
import { CdkDropdownComponent } from './cdk-dropdown/cdk-dropdown.component'
import { CollapseComponent } from './collapse/collapse.component'
import { FormatCountdownPipe } from './countdown/countdown-format.pipe'
import { CountdownComponent } from './countdown/countdown.component'
import { DataGridCheckboxComponent } from './data-grid-checkbox/data-grid-checkbox.component'
import { DataGridComponent } from './data-grid/data-grid.component'
import { DateRangeComponent } from './date-range/date-range.component'
import { ControlErrorsComponent } from './forms/control-errors/control-errors.component'
import { CounterInputComponent } from './forms/counter-input/counter-input.component'
import { DateInputComponent } from './forms/date-input/date-input.component'
import { DateTimeDurationInputComponent } from './forms/date-time-duration-input/date-time-duration-input.component'
import { DateTimeInputComponent } from './forms/date-time-input/date-time-input.component'
import { SelectCountriesComponent } from './forms/select-countries/select-countries.component'
import { SelectSearchComponent } from './forms/select-search/select-search.component'
import { SelectComponent } from './forms/select/select.component'
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component'
import { ModalComponent } from './modal/modal.component'
import { ProgressBarComponent } from './progress-bar/progress-bar.component'
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component'
import { TooltipComponent } from './tooltip/tooltip.component'
import { TooltipDirective } from './tooltip/tooltip.directive'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgSelectModule,
    OverlayModule,
    PaginationModule,
    AgGridModule.withComponents([DataGridCheckboxComponent]),
    MatNativeDateModule,
    MatDatepickerModule,
    TranslateModule.forChild(),
    CountryFlagModule,
    IconsModule,
    PortalModule,
  ],
  declarations: [
    BreadcrumbsComponent,
    CollapseComponent,
    ControlErrorsComponent,
    CounterInputComponent,
    DataGridCheckboxComponent,
    DataGridComponent,
    DateInputComponent,
    DateTimeInputComponent,
    DateTimeDurationInputComponent,
    ModalComponent,
    ProgressBarComponent,
    SelectComponent,
    SelectSearchComponent,
    TooltipComponent,
    TooltipDirective,
    DateRangeComponent,
    CounterInputComponent,
    ToggleSwitchComponent,
    LoadingSpinnerComponent,
    SelectCountriesComponent,
    CdkDropdownComponent,
    CountdownComponent,
    FormatCountdownPipe,
    AnnouncementBannerComponent,
  ],
  exports: [
    BreadcrumbsComponent,
    CollapseComponent,
    ControlErrorsComponent,
    CounterInputComponent,
    DataGridCheckboxComponent,
    DataGridComponent,
    DateInputComponent,
    DateTimeInputComponent,
    DateTimeDurationInputComponent,
    ModalComponent,
    ProgressBarComponent,
    SelectComponent,
    SelectSearchComponent,
    TooltipComponent,
    TooltipDirective,
    DateRangeComponent,
    CounterInputComponent,
    ToggleSwitchComponent,
    LoadingSpinnerComponent,
    SelectCountriesComponent,
    CdkDropdownComponent,
    CountdownComponent,
    AnnouncementBannerComponent,
  ],
})
export class SharedComponentsModule {}
