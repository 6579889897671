import { StyleModel } from '@tba/stripe'

export const CHANNEL_STYLES: { [key: string]: StyleModel } = {
  default: {
    base: {
      fontFamily: 'ArialMT',
      padding: '12px',
      fontSize: '16px',
    },
  },
  art: {
    base: {
      fontFamily: 'freight-display-pro',
      padding: '12px',
      fontSize: '16px',
    },
  },
  troostwijk: {
    base: {
      fontFamily: 'industry',
      padding: '12px',
      fontSize: '16px',
    },
  },
}
