export enum PalletType {
  INFO = 'info',
  WARNING = 'warning',
  FAIL = 'fail',
  SUCCESS = 'success',
}

export interface ChannelPallet {
  backgroundColor: string
  color: string
  width: string
  height: string
}

export const CHANNEL_PALLET: { [key: string]: ChannelPallet } = {
  [PalletType.INFO]: {
    backgroundColor: '#D0E7FC',
    color: '#2683BE',
    width: '20px',
    height: '20px',
  },
  [PalletType.WARNING]: {
    backgroundColor: '#FBEED5',
    color: '#E9A01A',
    width: '20px',
    height: '20px',
  },
  [PalletType.FAIL]: {
    backgroundColor: '#FFDED1',
    color: '#BF2E00',
    width: '20px',
    height: '20px',
  },
  [PalletType.SUCCESS]: {
    backgroundColor: '#D0F9E6',
    color: '#007B3A',
    width: '20px',
    height: '20px',
  },
}
