import { Inject, Injectable } from '@angular/core'
import { Environment } from '@tba/portal/shared/models'
import { ENVIRONMENT } from '@tba/shared/injection-tokens'

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor(@Inject(ENVIRONMENT) private environment: Environment) {}

  getEnv(): Environment {
    return this.environment
  }
}
