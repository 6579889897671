import { CountryIso } from '@tba/shared/models'

export function isEUCountry(isoCode: string): boolean {
  return isoEUCountries.includes(isoCode)
}

export const countries: CountryIso[] = [
  {
    isoCode: 'al',
    name: {
      nl: 'Albanië',
      en: 'Albania',
      de: 'Albanien',
    },
    countryCode: '+355',
  },
  {
    isoCode: 'dz',
    name: {
      nl: 'Algerije',
      en: 'Algeria',
      de: 'Algerien',
    },
    countryCode: '+213',
  },
  {
    isoCode: 'as',
    name: {
      nl: 'Amerikaans-Samoa',
      en: 'American Samoa',
      de: 'Amerikanisch-Samoa',
    },
    countryCode: '+1684',
  },
  {
    isoCode: 'vg',
    name: {
      nl: 'Amerikaanse Maagdeneilanden',
      en: 'American virgin islands',
      de: 'Amerikanische Jungferninseln',
    },
    countryCode: '+1284',
  },
  {
    isoCode: 'ad',
    name: {
      nl: 'Andorra',
      en: 'Andorra',
      de: 'Andorra',
    },
    countryCode: '+376',
  },
  {
    isoCode: 'ao',
    name: {
      nl: 'Angola',
      en: 'Angola',
      de: 'Angola',
    },
    countryCode: '+244',
  },
  {
    isoCode: 'ai',
    name: {
      nl: 'Anguilla',
      en: 'Anguilla',
      de: 'Anguilla',
    },
    countryCode: '+1264',
  },
  {
    isoCode: 'aq',
    name: {
      nl: 'Antartica',
      en: 'Antartica',
      de: 'Antartica',
    },
    countryCode: '+672',
  },
  {
    isoCode: 'ar',
    name: {
      nl: 'Argentinië',
      en: 'Argentina',
      de: 'Argentinien',
    },
    countryCode: '+54',
  },
  {
    isoCode: 'am',
    name: {
      nl: 'Armenië',
      en: 'Armenia',
      de: 'Armenien',
    },
    countryCode: '+374',
  },
  {
    isoCode: 'aw',
    name: {
      nl: 'Aruba',
      en: 'Aruba',
      de: 'Aruba',
    },
    countryCode: '+297',
  },
  {
    isoCode: 'au',
    name: {
      nl: 'Australië',
      en: 'Australia',
      de: 'Australien',
    },
    countryCode: '+61',
  },
  {
    isoCode: 'ag',
    name: {
      nl: 'Antigua en Barbuda',
      en: 'Antigua in Barbuda',
      de: 'Antigua in Barbuda',
    },
    countryCode: '+1268',
  },
  {
    isoCode: 'az',
    name: {
      nl: 'Azerbeidzjan',
      en: 'Azerbaijan',
      de: 'Aserbaidschan',
    },
    countryCode: '+994',
  },
  {
    isoCode: 'bd',
    name: {
      nl: 'Bangladesh',
      en: 'Bangladesh',
      de: 'Bangladesch',
    },
    countryCode: '+880',
  },
  {
    isoCode: 'be',
    name: {
      nl: 'België',
      en: 'Belgium',
      de: 'Belgien',
    },
    countryCode: '+32',
    EU: true,
  },
  {
    isoCode: 'bz',
    name: {
      nl: 'Belize',
      en: 'Belize',
      de: 'Belize',
    },
    countryCode: '+501',
  },
  {
    isoCode: 'bj',
    name: {
      nl: 'Benin',
      en: 'Benin',
      de: 'Benin',
    },
    countryCode: '+229',
  },
  {
    isoCode: 'bm',
    name: {
      nl: 'Bermuda',
      en: 'Bermuda',
      de: 'Bermuda',
    },
    countryCode: '+1441',
  },
  {
    isoCode: 'bo',
    name: {
      nl: 'Bolivië',
      en: 'Bolivia',
      de: 'Bolivien',
    },
    countryCode: '+591',
  },
  {
    isoCode: 'bt',
    name: {
      nl: 'Bhutan',
      en: 'Bhutan',
      de: 'Bhutan',
    },
    countryCode: '+975',
  },
  {
    isoCode: 'ba',
    name: {
      nl: 'Bosnië en Herzegovina',
      en: 'Bosnia and Herzegovina',
      de: 'Bosnien und Herzegowina',
    },
    countryCode: '+387',
  },
  {
    isoCode: 'bv',
    name: {
      nl: 'Bouvet Island',
      en: 'Bouvet Island',
      de: 'Bouvet Island',
    },
    countryCode: '+47',
  },
  {
    isoCode: 'br',
    name: {
      nl: 'Brazilië',
      en: 'Brazil',
      de: 'Brasilien',
    },
    countryCode: '+55',
  },
  {
    isoCode: 'io',
    name: {
      nl: 'British Indian Ocean Territory',
      en: 'British Indian Ocean Territory',
      de: 'Britisches Territorium des Indischen Ozeans',
    },
    countryCode: '+246',
  },
  {
    isoCode: 'bn',
    name: {
      nl: 'Brunei',
      en: 'Brunei',
      de: 'Brunei',
    },
    countryCode: '+673',
  },
  {
    isoCode: 'bg',
    name: {
      nl: 'Bulgarije',
      en: 'Bulgaria',
      de: 'Bulgarien',
    },
    countryCode: '+359',
    EU: true,
  },
  {
    isoCode: 'bf',
    name: {
      nl: 'Burkina Faso',
      en: 'Burkina Faso',
      de: 'Burkina Faso',
    },
    countryCode: '+226',
  },
  {
    isoCode: 'bi',
    name: {
      nl: 'Burundi',
      en: 'Burundi',
      de: 'Burundi',
    },
    countryCode: '+257',
  },
  {
    isoCode: 'ca',
    name: {
      nl: 'Canada',
      en: 'Canada',
      de: 'Kanada',
    },
    countryCode: '+1',
  },
  {
    isoCode: 'ky',
    name: {
      nl: 'Cayman Islands',
      en: 'Cayman Islands',
      de: 'Cayman Inseln',
    },
    countryCode: '+1345',
  },
  {
    isoCode: 'cl',
    name: {
      nl: 'Chili',
      en: 'Chili',
      de: 'Chili',
    },
    countryCode: '+56',
  },
  {
    isoCode: 'cn',
    name: {
      nl: 'China',
      en: 'China',
      de: 'China',
    },
    countryCode: '+86',
  },
  {
    isoCode: 'cx',
    name: {
      nl: 'Christmas Island',
      en: 'Christmas Island',
      de: 'Weihnachtsinsel',
    },
    countryCode: '+61',
  },
  {
    isoCode: 'cc',
    name: {
      nl: 'Cocos (Keeling) Islands',
      en: 'Cocos (Keeling) Islands',
      de: 'Kokosinseln (Keelinginseln)',
    },
    countryCode: '+61',
  },
  {
    isoCode: 'co',
    name: {
      nl: 'Colombia',
      en: 'Colombia',
      de: 'Kolumbien',
    },
    countryCode: '+57',
  },
  {
    isoCode: 'km',
    name: {
      nl: 'Comoren',
      en: 'Comoros',
      de: 'Komoren',
    },
    countryCode: '+269',
  },
  {
    isoCode: 'cd',
    name: {
      nl: 'Congo, The Democratic Republic Of The',
      en: 'Congo, The Democratic Republic Of The',
      de: 'Kongo, die Demokratische Republik',
    },
    countryCode: '+243',
  },
  {
    isoCode: 'ck',
    name: {
      nl: 'Cook Islands',
      en: 'Cook Islands',
      de: 'Cookinseln',
    },
    countryCode: '+682',
  },
  {
    isoCode: 'cr',
    name: {
      nl: 'Costa Rica',
      en: 'Costa Rica',
      de: 'Costa Rica',
    },
    countryCode: '+506',
  },
  {
    isoCode: 'cu',
    name: {
      nl: 'Cuba',
      en: 'Cuba',
      de: 'Kuba',
    },
    countryCode: '+53',
  },
  {
    isoCode: 'cy',
    name: {
      nl: 'Cyprus',
      en: 'Cyprus',
      de: 'Zypern',
    },
    countryCode: '+357',
    EU: true,
  },
  {
    isoCode: 'dk',
    name: {
      nl: 'Denemarken',
      en: 'Denmark',
      de: 'Dänemark',
    },
    countryCode: '+45',
    EU: true,
  },
  {
    isoCode: 'dj',
    name: {
      nl: 'Djibouti',
      en: 'Djibouti',
      de: 'Dschibuti',
    },
    countryCode: '+253',
  },
  {
    isoCode: 'dm',
    name: {
      nl: 'Dominica',
      en: 'Dominica',
      de: 'Dominica',
    },
    countryCode: '+1767',
  },
  {
    isoCode: 'do',
    name: {
      nl: 'Dominicaanse Republiek',
      en: 'Dominican Republic',
      de: 'Dominikanische Republik',
    },
    countryCode: '+1809',
  },
  {
    isoCode: 'de',
    name: {
      nl: 'Duitsland',
      en: 'Germany',
      de: 'Deutschland',
    },
    countryCode: '+49',
    EU: true,
  },
  {
    isoCode: 'eg',
    name: {
      nl: 'Egypte',
      en: 'Egypt',
      de: 'Ägypten',
    },
    countryCode: '+20',
  },
  {
    isoCode: 'sv',
    name: {
      nl: 'El Salvador',
      en: 'The Savior',
      de: 'El Salvador',
    },
    countryCode: '+503',
  },
  {
    isoCode: 'ec',
    name: {
      nl: 'Equador',
      en: 'Equador',
      de: 'Vereinigte Staaten',
    },
    countryCode: '+593',
  },
  {
    isoCode: 'gq',
    name: {
      nl: 'Equatoriaal-Guinea',
      en: 'Equatorial Guinea',
      de: 'Äquatorialguinea',
    },
    countryCode: '+240',
  },
  {
    isoCode: 'er',
    name: {
      nl: 'Eritrea',
      en: 'Eritrea',
      de: 'Eritrea',
    },
    countryCode: '+291',
  },
  {
    isoCode: 'ee',
    name: {
      nl: 'Estland',
      en: 'Estonia',
      de: 'Estland',
    },
    countryCode: '+372',
    EU: true,
  },
  {
    isoCode: 'et',
    name: {
      nl: 'Ethiopië',
      en: 'Ethiopia',
      de: 'Äthiopien',
    },
    countryCode: '+251',
  },
  {
    isoCode: 'fo',
    name: {
      nl: 'Faeröer',
      en: 'Faroe Islands',
      de: 'Färöer',
    },
    countryCode: '+298',
  },
  {
    isoCode: 'fk',
    name: {
      nl: 'Falklandeilanden',
      en: 'Falkland Islands',
      de: 'Falklandinseln',
    },
    countryCode: '+500',
  },
  {
    isoCode: 'fj',
    name: {
      nl: 'Fiji',
      en: 'Fiji',
      de: 'Fidschi',
    },
    countryCode: '+679',
  },
  {
    isoCode: 'ph',
    name: {
      nl: 'Filipijnen',
      en: 'Philippines',
      de: 'Philippinen',
    },
    countryCode: '+63',
  },
  {
    isoCode: 'fi',
    name: {
      nl: 'Finland',
      en: 'Finland',
      de: 'Finnland',
    },
    countryCode: '+358',
    EU: true,
  },
  {
    isoCode: 'fr',
    name: {
      nl: 'Frankrijk',
      en: 'France',
      de: 'Frankreich',
    },
    countryCode: '+33',
    EU: true,
  },
  {
    isoCode: 'gf',
    name: {
      nl: 'Frans-Guyana',
      en: 'French Guyana',
      de: 'Französisch-Guayana',
    },
    countryCode: '+594',
  },
  {
    isoCode: 'pf',
    name: {
      nl: 'Frans-Polynesië',
      en: 'French Polynesia',
      de: 'Französisch-Polynesien',
    },
    countryCode: '+689',
  },
  {
    isoCode: 'tf',
    name: {
      nl: 'French Southern and Antarctic Territories',
      en: 'French Southern and Antarctic Territories',
      de: 'Französische Süd- und Antarktisgebiete',
    },
    countryCode: '+262',
  },
  {
    isoCode: 'ga',
    name: {
      nl: 'Gabon',
      en: 'Gabon',
      de: 'Gabun',
    },
    countryCode: '+241',
  },
  {
    isoCode: 'gm',
    name: {
      nl: 'Gambia',
      en: 'Gambia',
      de: 'Gambia',
    },
    countryCode: '+220',
  },
  {
    isoCode: 'ge',
    name: {
      nl: 'Georgië',
      en: 'Georgia',
      de: 'Georgia',
    },
    countryCode: '+995',
  },
  {
    isoCode: 'gi',
    name: {
      nl: 'Gibraltar',
      en: 'Gibraltar',
      de: 'Gibraltar',
    },
    countryCode: '+350',
  },
  {
    isoCode: 'gd',
    name: {
      nl: 'Grenada',
      en: 'Grenada',
      de: 'Grenada',
    },
    countryCode: '+1473',
  },
  {
    isoCode: 'gr',
    name: {
      nl: 'Griekenland',
      en: 'Greece',
      de: 'Griechenland',
    },
    countryCode: '+30',
    EU: true,
  },
  {
    isoCode: 'gl',
    name: {
      nl: 'Groenland',
      en: 'Greenland',
      de: 'Grönland',
    },
    countryCode: '+299',
  },
  {
    isoCode: 'gp',
    name: {
      nl: 'Guadeloupe',
      en: 'Guadeloupe',
      de: 'Guadeloupe',
    },
    countryCode: '+590',
  },
  {
    isoCode: 'gu',
    name: {
      nl: 'Guam',
      en: 'Guam',
      de: 'Guam',
    },
    countryCode: '+1671',
  },
  {
    isoCode: 'gt',
    name: {
      nl: 'Guatemala',
      en: 'Guatemala',
      de: 'Guatemala',
    },
    countryCode: '+502',
  },
  {
    isoCode: 'gn',
    name: {
      nl: 'Guinee',
      en: 'Guinea',
      de: 'Guinea',
    },
    countryCode: '+224',
  },
  {
    isoCode: 'gw',
    name: {
      nl: 'Guinee-Bissau',
      en: 'Guinea-Bissau',
      de: 'Guinea-Bissau',
    },
    countryCode: '+245',
  },
  {
    isoCode: 'gy',
    name: {
      nl: 'Guyana',
      en: 'Guyana',
      de: 'Guyana',
    },
    countryCode: '+592',
  },
  {
    isoCode: 'ht',
    name: {
      nl: 'Haïti',
      en: 'Haiti',
      de: 'Haiti',
    },
    countryCode: '+509',
  },
  {
    isoCode: 'hm',
    name: {
      nl: 'Heard Island and Mcdonald Islands',
      en: 'Heard Island and Mcdonald Islands',
      de: 'Heard Island und Mcdonald Islands',
    },
    countryCode: '+672',
  },
  {
    isoCode: 'va',
    name: {
      nl: 'Heilige Stoel',
      en: 'Holy Chair',
      de: 'Heiliger Stuhl',
    },
    countryCode: '+379',
  },
  {
    isoCode: 'hn',
    name: {
      nl: 'Honduras',
      en: 'Honduras',
      de: 'Honduras',
    },
    countryCode: '+504',
  },
  {
    isoCode: 'hk',
    name: {
      nl: 'Hong Kong',
      en: 'Hong Kong',
      de: 'Hongkong',
    },
    countryCode: '+852',
  },
  {
    isoCode: 'hu',
    name: {
      nl: 'Hongarije',
      en: 'Hungary',
      de: 'Ungarn',
    },
    countryCode: '+36',
    EU: true,
  },
  {
    isoCode: 'ie',
    name: {
      nl: 'Ierland',
      en: 'Ireland',
      de: 'Irland',
    },
    countryCode: '+353',
    EU: true,
  },
  {
    isoCode: 'is',
    name: {
      nl: 'IJsland',
      en: 'Iceland',
      de: 'Island',
    },
    countryCode: '+354',
  },
  {
    isoCode: 'in',
    name: {
      nl: 'India',
      en: 'India',
      de: 'Indien',
    },
    countryCode: '+91',
  },
  {
    isoCode: 'id',
    name: {
      nl: 'Indonesië',
      en: 'Indonesia',
      de: 'Indonesien',
    },
    countryCode: '+62',
  },
  {
    isoCode: 'iq',
    name: {
      nl: 'Irak',
      en: 'Iraq',
      de: 'Irak',
    },
    countryCode: '+964',
  },
  {
    isoCode: 'il',
    name: {
      nl: 'Israël',
      en: 'Israel',
      de: 'Israel',
    },
    countryCode: '+972',
  },
  {
    isoCode: 'it',
    name: {
      nl: 'Italië',
      en: 'Italy',
      de: 'Italien',
    },
    countryCode: '+39',
    EU: true,
  },
  {
    isoCode: 'ci',
    name: {
      nl: 'Ivoorkust',
      en: 'Ivory Coast',
      de: 'Elfenbeinküste',
    },
    countryCode: '+225',
  },
  {
    isoCode: 'jp',
    name: {
      nl: 'Japan',
      en: 'Japan',
      de: 'Japan',
    },
    countryCode: '+81',
  },
  {
    isoCode: 'jo',
    name: {
      nl: 'Jordanië',
      en: 'Jordan',
      de: 'Jordanien',
    },
    countryCode: '+962',
  },
  {
    isoCode: 'cv',
    name: {
      nl: 'Kaapverdië',
      en: 'Cape Verde',
      de: 'Kap Verde',
    },
    countryCode: '+238',
  },
  {
    isoCode: 'cm',
    name: {
      nl: 'Kameroen',
      en: 'Cameroon',
      de: 'Kameroen',
    },
    countryCode: '+237',
  },
  {
    isoCode: 'kz',
    name: {
      nl: 'Kazachstan',
      en: 'Kazakhstan',
      de: 'Kazachstan',
    },
    countryCode: '+7',
  },
  {
    isoCode: 'ke',
    name: {
      nl: 'Kenia',
      en: 'Kenya',
      de: 'Kenia',
    },
    countryCode: '+254',
  },
  {
    isoCode: 'kb',
    name: {
      nl: 'Kingdom of Bahrain',
      en: 'Kingdom of Bahrain',
      de: 'Königreich Bahrain',
    },
    countryCode: '+973',
  },
  {
    isoCode: 'kg',
    name: {
      nl: 'Kirgizië',
      en: 'Kyrgyzstan',
      de: 'Kirgisistan',
    },
    countryCode: '+996',
  },
  {
    isoCode: 'ki',
    name: {
      nl: 'Kiribati',
      en: 'Kiribati',
      de: 'Kiribati',
    },
    countryCode: '+686',
  },
  {
    isoCode: 'cg',
    name: {
      nl: 'Kongo',
      en: 'Congo',
      de: 'Kongo',
    },
    countryCode: '+242',
  },
  {
    isoCode: 'xk',
    name: {
      nl: 'Kosovo',
      en: 'Kosovo',
      de: 'Kosovo',
    },
    countryCode: '+383',
  },
  {
    isoCode: 'hr',
    name: {
      nl: 'Kroatië',
      en: 'Croatia',
      de: 'Kroatien',
    },
    countryCode: '+385',
    EU: true,
  },
  {
    isoCode: 'kw',
    name: {
      nl: 'Kuwait',
      en: 'Kuwait',
      de: 'Kuwait',
    },
    countryCode: '+965',
  },
  {
    isoCode: 'la',
    name: {
      nl: 'Laos',
      en: 'Laos',
      de: 'Laos',
    },
    countryCode: '+856',
  },
  {
    isoCode: 'lv',
    name: {
      nl: 'Letland',
      en: 'Latvia',
      de: 'Lettland',
    },
    countryCode: '+371',
    EU: true,
  },
  {
    isoCode: 'lb',
    name: {
      nl: 'Libanon',
      en: 'Lebanon',
      de: 'Libanon',
    },
    countryCode: '+961',
  },
  {
    isoCode: 'lr',
    name: {
      nl: 'Liberia',
      en: 'Liberia',
      de: 'Liberia',
    },
    countryCode: '+231',
  },
  {
    isoCode: 'ly',
    name: {
      nl: 'Libië',
      en: 'Libya',
      de: 'Libyen',
    },
    countryCode: '+218',
  },
  {
    isoCode: 'li',
    name: {
      nl: 'Liechtenstein',
      en: 'Liechtenstein',
      de: 'Liechtenstein',
    },
    countryCode: '+423',
  },
  {
    isoCode: 'lt',
    name: {
      nl: 'Litouwen',
      en: 'Lithuania',
      de: 'Litauen',
    },
    countryCode: '+370',
    EU: true,
  },
  {
    isoCode: 'lu',
    name: {
      nl: 'Luxemburg',
      en: 'Luxembourg',
      de: 'Luxemburg',
    },
    countryCode: '+352',
    EU: true,
  },
  {
    isoCode: 'mo',
    name: {
      nl: 'Macau',
      en: 'Macau',
      de: 'Macau',
    },
    countryCode: '+853',
  },
  {
    isoCode: 'mk',
    name: {
      nl: 'Macedonië',
      en: 'Macedonia',
      de: 'Mazedonien',
    },
    countryCode: '+389',
  },
  {
    isoCode: 'mg',
    name: {
      nl: 'Madagaskar',
      en: 'Madagascar',
      de: 'Madagaskar',
    },
    countryCode: '+261',
  },
  {
    isoCode: 'mw',
    name: {
      nl: 'Malawi',
      en: 'Malawi',
      de: 'Malawi',
    },
    countryCode: '+265',
  },
  {
    isoCode: 'mv',
    name: {
      nl: 'Maldiven',
      en: 'Maldives',
      de: 'Malediven',
    },
    countryCode: '+960',
  },
  {
    isoCode: 'my',
    name: {
      nl: 'Maleisië',
      en: 'Malaysia',
      de: 'Malaysia',
    },
    countryCode: '+60',
  },
  {
    isoCode: 'ml',
    name: {
      nl: 'Mali',
      en: 'mali',
      de: 'Mali',
    },
    countryCode: '+223',
  },
  {
    isoCode: 'mt',
    name: {
      nl: 'Malta',
      en: 'Malta',
      de: 'Malta',
    },
    countryCode: '+356',
    EU: true,
  },
  {
    isoCode: 'ma',
    name: {
      nl: 'Marokko',
      en: 'Morocco',
      de: 'Marokko',
    },
    countryCode: '+212',
  },
  {
    isoCode: 'mq',
    name: {
      nl: 'Martinique',
      en: 'Martinique',
      de: 'Martinique',
    },
    countryCode: '+596',
  },
  {
    isoCode: 'mr',
    name: {
      nl: 'Mauritanië',
      en: 'Mauritania',
      de: 'Mauretanien',
    },
    countryCode: '+222',
  },
  {
    isoCode: 'mu',
    name: {
      nl: 'Mauritius',
      en: 'Mauritius',
      de: 'Mauritius',
    },
    countryCode: '+230',
  },
  {
    isoCode: 'yt',
    name: {
      nl: 'Mayotte',
      en: 'Mayotte',
      de: 'Mayotte',
    },
    countryCode: '+262',
  },
  {
    isoCode: 'mx',
    name: {
      nl: 'Mexico',
      en: 'Mexico',
      de: 'Mexiko',
    },
    countryCode: '+52',
  },
  {
    isoCode: 'md',
    name: {
      nl: 'Moldavië',
      en: 'Moldavia',
      de: 'Moldawien',
    },
    countryCode: '+373',
  },
  {
    isoCode: 'mc',
    name: {
      nl: 'Monaco',
      en: 'Monaco',
      de: 'Monaco',
    },
    countryCode: '+377',
  },
  {
    isoCode: 'ms',
    name: {
      nl: 'Montserrat',
      en: 'Montserrat',
      de: 'Montserrat',
    },
    countryCode: '+1664',
  },
  {
    isoCode: 'mz',
    name: {
      nl: 'Mozambique',
      en: 'Mozambique',
      de: 'Mosambik',
    },
    countryCode: '+258',
  },
  {
    isoCode: 'na',
    name: {
      nl: 'Namibië',
      en: 'Namibia',
      de: 'Namibia',
    },
    countryCode: '+264',
  },
  {
    isoCode: 'nr',
    name: {
      nl: 'Nauru',
      en: 'Nauru',
      de: 'Nauru',
    },
    countryCode: '+674',
  },
  {
    isoCode: 'nl',
    name: {
      nl: 'Nederland',
      en: 'The Netherlands',
      de: 'Die Niederlande',
    },
    countryCode: '+31',
    EU: true,
  },
  {
    isoCode: 'an',
    name: {
      nl: 'Nederlandse Antillen',
      en: 'Netherlands Antilles',
      de: 'Niederländische Antillen',
    },
    countryCode: '+599',
  },
  {
    isoCode: 'np',
    name: {
      nl: 'Nepal',
      en: 'Nepal',
      de: 'Nepal',
    },
    countryCode: '+977',
  },
  {
    isoCode: 'nc',
    name: {
      nl: 'New Caledonia',
      en: 'New Caledonia',
      de: 'Neu-Kaledonien',
    },
    countryCode: '+687',
  },
  {
    isoCode: 'nz',
    name: {
      nl: 'Nieuw-Zeeland',
      en: 'New Zealand',
      de: 'Neuseeland',
    },
    countryCode: '+64',
  },
  {
    isoCode: 'ne',
    name: {
      nl: 'Niger',
      en: 'Niger',
      de: 'Niger',
    },
    countryCode: '+227',
  },
  {
    isoCode: 'ng',
    name: {
      nl: 'Nigeria',
      en: 'Nigeria',
      de: 'Nigeria',
    },
    countryCode: '+234',
  },
  {
    isoCode: 'nu',
    name: {
      nl: 'Niue',
      en: 'Niue',
      de: 'Niue',
    },
    countryCode: '+683',
  },
  {
    isoCode: 'no',
    name: {
      nl: 'Noorwegen',
      en: 'Norway',
      de: 'Norwegen',
    },
    countryCode: '+47',
  },
  {
    isoCode: 'nf',
    name: {
      nl: 'Norfolk Island',
      en: 'Norfolk Island',
      de: 'Norfolkinsel',
    },
    countryCode: '+672',
  },
  {
    isoCode: 'ua',
    name: {
      nl: 'Oekraïne',
      en: 'Ukraine',
      de: 'Ukraine',
    },
    countryCode: '+380',
  },
  {
    isoCode: 'uz',
    name: {
      nl: 'Oezbekistan',
      en: 'Uzbekistan',
      de: 'Usbekistan',
    },
    countryCode: '+998',
  },
  {
    isoCode: 'om',
    name: {
      nl: 'Oman',
      en: 'Oman',
      de: 'Oman',
    },
    countryCode: '+968',
  },
  {
    isoCode: 'at',
    name: {
      nl: 'Oostenrijk',
      en: 'Austria',
      de: 'Österreich',
    },
    countryCode: '+43',
    EU: true,
  },
  {
    isoCode: 'ps',
    name: {
      nl: 'Palestina',
      en: 'Palestine',
      de: 'Palästina',
    },
    countryCode: '+970',
  },
  {
    isoCode: 'pa',
    name: {
      nl: 'Panama',
      en: 'Panama',
      de: 'Panama',
    },
    countryCode: '+507',
  },
  {
    isoCode: 'pg',
    name: {
      nl: 'Papoea-Nieuw-Guinea',
      en: 'Papua New Guinea',
      de: 'Papua-Neuguinea',
    },
    countryCode: '+675',
  },
  {
    isoCode: 'py',
    name: {
      nl: 'Paraguay',
      en: 'Paraguay',
      de: 'Paraguay',
    },
    countryCode: '+595',
  },
  {
    isoCode: 'pe',
    name: {
      nl: 'Peru',
      en: 'Peru',
      de: 'Peru',
    },
    countryCode: '+51',
  },
  {
    isoCode: 'pn',
    name: {
      nl: 'Pitcairn Islands',
      en: 'Pitcairn Islands',
      de: 'Pitcairn-Inseln',
    },
    countryCode: '+64',
  },
  {
    isoCode: 'pl',
    name: {
      nl: 'Polen',
      en: 'Poland',
      de: 'Polen',
    },
    countryCode: '+48',
    EU: true,
  },
  {
    isoCode: 'pt',
    name: {
      nl: 'Portugal',
      en: 'Portugal',
      de: 'Portugal',
    },
    countryCode: '+351',
    EU: true,
  },
  {
    isoCode: 'qa',
    name: {
      nl: 'Qatar',
      en: 'Qatar',
      de: 'Katar',
    },
    countryCode: '+974',
  },
  {
    isoCode: 'ro',
    name: {
      nl: 'Roemenië',
      en: 'Romania',
      de: 'Rumänien',
    },
    countryCode: '+40',
    EU: true,
  },
  {
    isoCode: 'ru',
    name: {
      nl: 'Rusland',
      en: 'Russia',
      de: 'Russland',
    },
    countryCode: '+7',
  },
  {
    isoCode: 'rw',
    name: {
      nl: 'Rwanda',
      en: 'Rwanda',
      de: 'Ruanda',
    },
    countryCode: '+250',
  },
  {
    isoCode: 're',
    name: {
      nl: 'Réunion',
      en: 'Meeting',
      de: 'Réunion',
    },
    countryCode: '+262',
  },
  {
    isoCode: 'kn',
    name: {
      nl: 'Saint Kitts en Nevis',
      en: 'Saint Kitts and Nevis',
      de: 'St. Kitts und Nevis',
    },
    countryCode: '+1869',
  },
  {
    isoCode: 'pm',
    name: {
      nl: 'Saint Pierre and Miquelon',
      en: 'Saint Pierre and Miquelon',
      de: 'St. Peter und Miquelon',
    },
    countryCode: '+508',
  },
  {
    isoCode: 'vc',
    name: {
      nl: 'Saint Vincent en de Grenadines',
      en: 'Saint Vincent in Grenadines',
      de: 'St. Vincent in den Grenadinen',
    },
    countryCode: '+1784',
  },
  {
    isoCode: 'sb',
    name: {
      nl: 'Salomonseilanden',
      en: 'Solomon Islands',
      de: 'Salomon-Inseln',
    },
    countryCode: '+677',
  },
  {
    isoCode: 'ws',
    name: {
      nl: 'Samoa',
      en: 'Samoa',
      de: 'Samoa',
    },
    countryCode: '+685',
  },
  {
    isoCode: 'sm',
    name: {
      nl: 'San Marino',
      en: 'San Marino',
      de: 'San Marino',
    },
    countryCode: '+378',
  },
  {
    isoCode: 'st',
    name: {
      nl: 'Sao Tomé en Principe',
      en: 'Sao Tomé en Principe',
      de: 'Sao Tomé en Principe',
    },
    countryCode: '+239',
  },
  {
    isoCode: 'sa',
    name: {
      nl: 'Saoedi-Arabië',
      en: 'Saudi Arabia',
      de: 'Saudi-Arabien',
    },
    countryCode: '+966',
  },
  {
    isoCode: 'rs',
    name: {
      nl: 'Servië',
      en: 'Serbia',
      de: 'Serbien',
    },
    countryCode: '+381',
  },
  {
    isoCode: 'sn',
    name: {
      nl: 'Senegal',
      en: 'Senegal',
      de: 'Senegal',
    },
    countryCode: '+221',
  },
  {
    isoCode: 'sc',
    name: {
      nl: 'Seychellen',
      en: 'Seychelles',
      de: 'Seychellen',
    },
    countryCode: '+248',
  },
  {
    isoCode: 'sl',
    name: {
      nl: 'Sierra Leone',
      en: 'Sierra Leone',
      de: 'Sierra Leone',
    },
    countryCode: '+232',
  },
  {
    isoCode: 'sg',
    name: {
      nl: 'Singapore',
      en: 'Singapore',
      de: 'Singapur',
    },
    countryCode: '+65',
  },
  {
    isoCode: 'sh',
    name: {
      nl: 'Sint-Helena',
      en: 'Sint-Helena',
      de: 'Sint-Helena',
    },
    countryCode: '+290',
  },
  {
    isoCode: 'si',
    name: {
      nl: 'Slovenië',
      en: 'Slovenia',
      de: 'Slowenien',
    },
    countryCode: '+386',
    EU: true,
  },
  {
    isoCode: 'sk',
    name: {
      nl: 'Slowakije',
      en: 'Slovakia',
      de: 'Slowakei',
    },
    countryCode: '+421',
    EU: true,
  },
  {
    isoCode: 'so',
    name: {
      nl: 'Somalië',
      en: 'Somalia',
      de: 'Somalia',
    },
    countryCode: '+252',
  },
  {
    isoCode: 'es',
    name: {
      nl: 'Spanje',
      en: 'Spain',
      de: 'Spanien',
    },
    countryCode: '+34',
    EU: true,
  },
  {
    isoCode: 'lk',
    name: {
      nl: 'Sri Lanka',
      en: 'Sri Lanka',
      de: 'Sri Lanka',
    },
    countryCode: '+94',
  },
  {
    isoCode: 'lc',
    name: {
      nl: 'St. Lucia',
      en: 'St. Lucia',
      de: 'St. Lucia',
    },
    countryCode: '+1758',
  },
  {
    isoCode: 'sr',
    name: {
      nl: 'Suriname',
      en: 'Suriname',
      de: 'Suriname',
    },
    countryCode: '+597',
  },
  {
    isoCode: 'sj',
    name: {
      nl: 'Svalbard and Jan Mayen',
      en: 'Svalbard and Jan Mayen',
      de: 'Svalbard und Jan Mayen',
    },
    countryCode: '+47',
  },
  {
    isoCode: 'sz',
    name: {
      nl: 'Swaziland',
      en: 'Swaziland',
      de: 'Swasiland',
    },
    countryCode: '+268',
  },
  {
    isoCode: 'tj',
    name: {
      nl: 'Tadzjikistan',
      en: 'Tajikistan',
      de: 'Tadschikistan',
    },
    countryCode: '+992',
  },
  {
    isoCode: 'tw',
    name: {
      nl: 'Taiwan',
      en: 'Taiwan',
      de: 'Taiwan',
    },
    countryCode: '+886',
  },
  {
    isoCode: 'tz',
    name: {
      nl: 'Tanzania',
      en: 'Tanzania',
      de: 'Tansania',
    },
    countryCode: '+255',
  },
  {
    isoCode: 'th',
    name: {
      nl: 'Thailand',
      en: 'Thailand',
      de: 'Thailand',
    },
    countryCode: '+66',
  },
  {
    isoCode: 'tg',
    name: {
      nl: 'Togo',
      en: 'Togo',
      de: 'Gehen',
    },
    countryCode: '+228',
  },
  {
    isoCode: 'tk',
    name: {
      nl: 'Tokelau-eilanden',
      en: 'Tokelau islands',
      de: 'Tokelau-Inseln',
    },
    countryCode: '+690',
  },
  {
    isoCode: 'to',
    name: {
      nl: 'Tonga',
      en: 'Tonga',
      de: 'Tonga',
    },
    countryCode: '+676',
  },
  {
    isoCode: 'cf',
    name: {
      nl: 'Centraal-Afrikaanse Republiek',
      en: 'Central-African Republic',
      de: 'Republik Zentralafrika',
    },
    countryCode: '+236',
  },
  {
    isoCode: 'td',
    name: {
      nl: 'Tsjaad',
      en: 'Chad',
      de: 'Tschad',
    },
    countryCode: '+235',
  },
  {
    isoCode: 'cz',
    name: {
      nl: 'Tsjechië',
      en: 'Czech Republic',
      de: 'Tschechische Republik',
    },
    countryCode: '+420',
    EU: true,
  },
  {
    isoCode: 'tn',
    name: {
      nl: 'Tunesië',
      en: 'Tunisia',
      de: 'Tunesien',
    },
    countryCode: '+216',
  },
  {
    isoCode: 'tr',
    name: {
      nl: 'Türkiye',
      en: 'Türkiye',
      de: 'Die Türkei',
    },
    countryCode: '+90',
  },
  {
    isoCode: 'tm',
    name: {
      nl: 'Turkmenistan',
      en: 'Turkmenistan',
      de: 'Turkmenistan',
    },
    countryCode: '+993',
  },
  {
    isoCode: 'tc',
    name: {
      nl: 'Turks- en Caicoseilanden',
      en: 'Turks and Caicos Islands',
      de: 'Turks- und Caicosinseln',
    },
    countryCode: '+1649',
  },
  {
    isoCode: 'tv',
    name: {
      nl: 'Tuvalu',
      en: 'Tuvalu',
      de: 'Tuvalu',
    },
    countryCode: '+688',
  },
  {
    isoCode: 'um',
    name: {
      nl: 'U. S. Minor Outlying Islands',
      en: 'U. S. Minor Outlying Islands',
      de: 'Kleinere vorgelagerte Inseln der USA',
    },
    countryCode: '+1340',
  },
  {
    isoCode: 'uy',
    name: {
      nl: 'Uruguay',
      en: 'Uruguay',
      de: 'Uruguay',
    },
    countryCode: '+598',
  },
  {
    isoCode: 'gb',
    name: {
      nl: 'Verenigd Koninkrijk van Groot-Brittanië en Noord-Ierland',
      en: 'United Kingdom of Great Britain and Northern Ireland',
      de: 'Vereinigtes Königreich Großbritannien und Nordirland',
    },
    countryCode: '+44',
    EU: true,
  },
  {
    isoCode: 'ae',
    name: {
      nl: 'Verenigde Arabische Emiraten',
      en: 'United Arab Emirates',
      de: 'Vereinigte Arabische Emirate',
    },
    countryCode: '+971',
  },
  {
    isoCode: 'us',
    name: {
      nl: 'Verenigde Staten van Amerika',
      en: 'United States',
      de: 'Vereinigte Staaten von Amerika',
    },
    countryCode: '+1',
  },
  {
    isoCode: 'vn',
    name: {
      nl: 'Vietnam',
      en: 'Vietnam',
      de: 'Vietnam',
    },
    countryCode: '+84',
  },
  {
    isoCode: 'wf',
    name: {
      nl: 'Wallis en Futuna',
      en: 'Wallis in Futuna',
      de: 'Wallis in Futuna',
    },
    countryCode: '+681',
  },
  {
    isoCode: 'eh',
    name: {
      nl: 'Westelijke Sahara',
      en: 'Western Sahara',
      de: 'Westsahara',
    },
    countryCode: '+212',
  },
  {
    isoCode: 'by',
    name: {
      nl: 'Wit-Rusland',
      en: 'Belarus',
      de: 'Weißrussland',
    },
    countryCode: '+375',
  },
  {
    isoCode: 'zm',
    name: {
      nl: 'Zambia',
      en: 'Zambia',
      de: 'Sambia',
    },
    countryCode: '+260',
  },
  {
    isoCode: 'za',
    name: {
      nl: 'Zuid-Afrika',
      en: 'South Africa',
      de: 'Südafrika',
    },
    countryCode: '+27',
  },
  {
    isoCode: 'gs',
    name: {
      nl: 'Zuid-Georgië en de Zuidelijke Sandwicheilanden',
      en: 'South Georgia and the South Sandwich Islands',
      de: 'Südgeorgien und die Südlichen Sandwichinseln',
    },
    countryCode: '+500',
  },
  {
    isoCode: 'kr',
    name: {
      nl: 'Zuid-Korea',
      en: 'South Korea',
      de: 'Südkorea',
    },
    countryCode: '+82',
  },
  {
    isoCode: 'se',
    name: {
      nl: 'Zweden',
      en: 'Sweden',
      de: 'Schweden',
    },
    countryCode: '+46',
    EU: true,
  },
  {
    isoCode: 'ch',
    name: {
      nl: 'Zwitserland',
      en: 'Switzerland',
      de: 'Schweiz',
    },
    countryCode: '+41',
  },
]

export const isoEUCountries: string[] = countries
  .filter(({ EU }: CountryIso) => EU)
  .map(({ isoCode }: CountryIso) => isoCode)
