import { Injectable } from '@angular/core'
import Cookies from 'js-cookie'

import { Channel } from '../utils/channel'

export interface BrandInfo {
  theme: string
  logoUrl: string
  faviconUrl: string
}

const brandInformations: { [channel: string]: BrandInfo } = {
  [Channel.Bva]: {
    theme: 'bva',
    logoUrl: 'assets/bva-nl-logo.svg',
    faviconUrl: 'assets/bva-favicon.png',
  },
  [Channel.Troostwijk]: {
    theme: 'troostwijk',
    logoUrl: 'assets/troostwijk-logo.svg',
    faviconUrl: 'assets/troostwijk-favicon.png',
  },
}

const STORAGE_KEY_CHANNEL_NAME: string = 'channelName'

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  brand: BrandInfo
  channelName: Channel
  initializeBrand(): void {
    const channelName: Channel =
      Cookies.get(`${STORAGE_KEY_CHANNEL_NAME}`) || Channel.Bva
    this.brand = brandInformations[channelName]
    this.channelName = this.brand.theme as Channel
    this.updateFavicon()
  }

  private updateFavicon(): void {
    const faviconElement: HTMLLinkElement =
      document.querySelector('#checkoutFavicon')
    if (faviconElement) {
      faviconElement.href = this.brand.faviconUrl
    }
  }
}
