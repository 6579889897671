import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'

import { IconRegistryService } from './icon-registry.service'
import { IconTextComponent } from './icon-text/icon-text.component'
import { IconComponent } from './icon.component'

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [IconComponent, IconTextComponent],
  providers: [IconRegistryService],
  exports: [IconComponent, IconTextComponent],
})
export class IconsModule {}
