import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core'
import { ModalConfig, ModalContent } from '@tba/shared/models'

import { ModalRef } from './modal-ref'

@Component({
  selector: 'tba-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnInit {
  renderMethod: 'template' | 'component' | 'text' = 'component'
  content: ModalContent
  context: { close: () => {} }
  config: ModalConfig = this.popoverRef.config

  constructor(private popoverRef: ModalRef) {}

  get title(): string {
    return this.popoverRef.title
  }

  get disableClose(): boolean {
    return this.popoverRef.disableClose
  }

  ngOnInit(): void {
    this.content = this.popoverRef.content

    if (typeof this.content === 'string') {
      this.renderMethod = 'text'
    } else if (this.content instanceof TemplateRef) {
      this.renderMethod = 'template'
      this.context = {
        close: this.popoverRef.close.bind(this.popoverRef),
      }
    }
  }

  onCloseModal(): void {
    this.popoverRef.close()
  }
}
