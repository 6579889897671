import { ModuleWithProviders, NgModule, Provider } from '@angular/core'

import { FeatureFlagsGuard } from './feature-flags.guard'
import { FeatureFlagsService } from './feature-flags.service'

@NgModule()
export class FeatureFlagsModule {
  static forRoot(config: {
    loader: Provider
  }): ModuleWithProviders<FeatureFlagsModule> {
    return {
      ngModule: FeatureFlagsModule,
      providers: [FeatureFlagsService, FeatureFlagsGuard, config.loader],
    }
  }
}
