import { Inject, Injectable } from '@angular/core'
import { LaunchDarklyService, User } from '@tba/shared/providers'
import { Observable } from 'rxjs'

import { FeatureFlagInit } from './feature-flag-init.model'
import { FEATURE_FLAGS_INIT } from './feature-flag.injection-token'
import { FeatureFlagsDictionaryModel } from './feature-flags-dictionary.model'

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  constructor(
    private ldService: LaunchDarklyService,
    @Inject(FEATURE_FLAGS_INIT) private init: FeatureFlagInit
  ) {
    const { envKey, user } = init

    this.ldService.initialize(envKey, user)
  }

  changeUser(user: User): void {
    this.ldService.changeUser(user)
  }

  getFlags(flag: string): Observable<boolean | FeatureFlagsDictionaryModel> {
    return this.ldService.getFlags(flag)
  }
}
