import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { CountryFlagComponent } from './components/country-flag/country-flag.component'

@NgModule({
  declarations: [CountryFlagComponent],
  imports: [CommonModule],
  exports: [CountryFlagComponent],
})
export class CountryFlagModule {}
